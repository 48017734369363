.ag-theme-alpine .ag-cell {
  white-space: pre-wrap; /* 改行を有効にする */
  overflow-wrap: break-word; /* 長い単語を折り返す */
}

.ag-large-text {
  top: 0 !important; /* セルの上部に合わせる */
  left: 0 !important; /* セルの左に合わせる */
  width: 100% !important; /* セルの幅に合わせる */
  height: 100% !important; /* セルの高さに合わせる */
  overflow: auto; /* スクロールを可能にする */
  resize: none; /* ユーザーによるサイズ変更を無効化 */
}

.ag-large-text-input{
  top: 0 !important; /* セルの上部に合わせる */
  left: 0 !important; /* セルの左に合わせる */
  padding: 0;
}


.row-black {
    background-color: black;
    color: white;
  }
  
  .row-gray {
    background-color: gray;
    color: black;
  }
  
  .row-striped:nth-child(even) {
    background-color: lightgray;
  }
  
  .row-default {
    background-color: white;
    color: black;
  }
  
  .row-black  .timestamp-color{
    color: white;
}

.row-gray  .timestamp-color{
  color: black;
}

.row-default .timestamp-color{
  color: black;
}